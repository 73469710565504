import { useBemCN } from '@gik/core/utils/bemBlock';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import React from 'react';
import { Layout } from '@gik/ui/gik/Layout/Layout';
import { HomePageHeroSection } from '../sections/HomePageHeroSection';
import { FeaturesSection } from '../sections/FeaturesSection';
import type { ICTASectionProps } from '../sections/CTASection';
import { CTASection } from '../sections/CTASection';
import { TestimonialsSection } from '../sections/TestimonialsSection';
import { FAQSection } from '../sections/FAQSection';
import { CreateClickedLocation } from '@gik/create/enums/CreateAnalyticsEnums';

export interface IHomePageProps {
  ctaSection: ICTASectionProps;
}

/**
 * https://www.figma.com/design/FT2ninjhNiQOXfnOTaZfqy/%F0%9F%91%A8%F0%9F%8F%BC%E2%80%8D%F0%9F%8E%A4-Jade's-workspace-of-asset-creation?node-id=4696-8640&m=dev
 */
function HomePageComp({ ctaSection }: IHomePageProps) {
  const bem = useBemCN('landing-section');
  return (
    <Layout element={'div'} direction={'column'} {...bem()}>
      <HomePageHeroSection />
      <FeaturesSection trackCreateClickedLocation={CreateClickedLocation.HOMEPAGE_HALF} />
      <CTASection {...ctaSection} />
      <TestimonialsSection />
      <FAQSection />
    </Layout>
  );
}

export const HomePage = withComponentErrorBoundary(HomePageComp);
