import type { PageLayoutProps } from '@/components/layout/PageLayout';
import { PageLayout } from '@/components/layout/PageLayout';
import type { FallbackProps } from '@gik/core/api/BaseAPIConfig';
import { withTitle } from '@gik/core/hocs/withTitle';
import { SWRConfig } from 'swr';
import type { IHomePageProps } from '@gik/landing-pages/pages/HomePage';
import { HomePage } from '@gik/landing-pages/pages/HomePage';
import React from 'react';
import routes from '@gik/core/routes';
import { UI } from '@gik/ui/UIManager';
import { LightboxMedia } from '@gik/ui/UIManager/UILightboxTypes';

export default function HomepageComp({ fallback }: FallbackProps<IHomePageProps>) {
  return withTitle(
    'Give InKind',
    <SWRConfig value={{ fallback }}>
      <HomePage {...homepageProps} />
    </SWRConfig>
  );
}

HomepageComp.getLayout = function getLayout(page: React.ReactElement, layoutProps: PageLayoutProps) {
  return (
    <PageLayout {...layoutProps} key={layoutProps.key}>
      {page}
    </PageLayout>
  );
};

const homepageProps: IHomePageProps = {
  ctaSection: {
    ctas: [
      {
        title: 'How Give InKind Works',
        content:
          'Learn why Give InKind is the easiest way to coordinate meaningful support through meal trains, gift cards, volunteer signups, and more—all in one place.',
        icon: `//useruploads.vwo.io/useruploads/744479/images/51d454f2a4f703f0a3970c7551cbe2f0_howgikworks.png`,
        linkIcon: true,
        onClickIcon: () => {
          UI.lightbox(
            [
              {
                url: 'https://www.youtube.com/watch?v=Z7TpGaI67rI',
                mediaType: LightboxMedia.VIDEO,
                videoProps: {
                  autoPlay: true,
                },
              },
            ],
            {
              title: 'How it Works',
            }
          );
        },
        buttonTitle: 'Learn More',
        buttonLink: routes.howItWork,
      },
      {
        title: 'Flexible and secure gifting',
        content:
          'Give InKind is the only website where recipients have 100% choice over how and where to use their gift. Our integrated, trackable gift cards mean no more lost or unused gifts.',
        icon: `//useruploads.vwo.io/useruploads/744479/images/fc34d7501a191f9abd1c4e67271557f3_flexiblesecuregifting.png`,
        buttonTitle: 'Read More',
        buttonLink: routes.organizeGiftCard,
      },
      {
        title: 'Free for Groups, too',
        content:
          'Whether you need to organize support for one person or one hundred, we are ready to help. Perfect for faith groups, HR, schools and more. Get started today!',
        icon: `//useruploads.vwo.io/useruploads/744479/images/e673d17532ba22d2315e29245268a507_freeforgroups.png`,
        buttonTitle: 'Read More',
        buttonLink: routes.forGroups,
      },
    ],
  },
};
